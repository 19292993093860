@import url('https://fonts.googleapis.com/css2?family=Habibi&family=Sofia+Sans:wght@1000&family=Sora:wght@400;600;700;800&display=swap');
@import 'css-reset.css';


body {
  width: 100%;
  background: hsl(0, 0%, 6%);
  font-family: 'Sora', sans-serif;
  scroll-behavior: smooth;
}

#root {
  overflow: hidden;
  position: relative;
}

a {
  text-decoration: none !important;
  color: white;
}